import axios from "axios";
import { logout } from "./auth";

export const AUTH_TOKEN_KEY = "auth_token"

const getAuthKey = () => localStorage.getItem(AUTH_TOKEN_KEY)

export const authAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 
        "Authorization": `Bearer ${getAuthKey()}`,
        // @ts-ignore
        "X-Kalotsavam-Zone": process.env.REACT_APP_FEST_ZONE_ID
    },
    validateStatus: (status) => {
        if (status === 401 || status === 403) {
            logout()
            return false
        } else {
            return true
        }
    }
})

authAxios.interceptors.request.use((config) => {
    config.headers = { ...config.headers, 
        "Authorization": `Bearer ${getAuthKey()}`,
        // @ts-ignore
        "X-Kalotsavam-Zone": process.env.REACT_APP_FEST_ZONE_ID
    };
    return config;
})

export const noAuthAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 
        // @ts-ignore
        "X-Kalotsavam-Zone": process.env.REACT_APP_FEST_ZONE_ID
    }
})

export const formToJson = (event: any) => {
    const formData = new FormData(event.currentTarget)
    const formJson:any = {};
    formData.forEach(function (value, key) {
        formJson[key] = value;
    });
    return formJson
} 
