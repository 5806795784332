import React, { FC, useEffect, useState } from 'react';
import Button from 'react-bootstrap/esm/Button'
import * as serviceWorker from '../serviceWorkerRegistration';

const ServiceWorkerWrapper: FC = () => {
    const [showReload, setShowReload] = useState(false);
    const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);

    const [supportsPWA, setSupportsPWA] = useState(false);
    const [promptInstall, setPromptInstall] = useState(null);

    const onSWUpdate = (registration: ServiceWorkerRegistration) => {
        setShowReload(true);
        setWaitingWorker(registration.waiting);
    };

    useEffect(() => {
        serviceWorker.register({ onUpdate: onSWUpdate });
        const handler = (e: any) => {
            e.preventDefault();
            setSupportsPWA(true);
            setPromptInstall(e);
        };
        window.addEventListener("beforeinstallprompt", handler);
        return () => window.removeEventListener("transitionend", handler);
    }, []);

    const reloadPage = () => {
        waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
        setShowReload(false);
        window.location.reload();
    };

    const handleInstallApp = (evt: any) => {
        evt.preventDefault();
        if (!promptInstall) {
            return;
        }
        //@ts-ignore
        promptInstall.prompt();
    };

    if (supportsPWA)
        return (
            <div className="fw-bold text-white text-center py-2" style={{ background: "rgba(0,0,0,0.7)" }} onClick={handleInstallApp}>
                Click here to install the app.
            </div>
        )

    if (showReload)
        return (
            <div className="fw-bold text-white text-center py-2" style={{ background: "rgba(0,0,0,0.7)" }}>
                A new version is available.
                <Button variant="link" size="sm" onClick={reloadPage} className="text-white fw-bold">Reload</Button>
            </div>
        )
    else
        return null
}

export default ServiceWorkerWrapper;
