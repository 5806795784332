import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FestDetails } from "../constants";

const SplashScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("home");
    }, 5000);
  }, [navigate]);

  return (
    <div
      className="bg-light text-dark d-flex flex-column align-items-center justify-content-between page"
      style={{ width: "100vw", height: "100vh" }}
    >
      <div>&nbsp;</div>
      <div className="p-4 rounded">
        <div className="d-flex flex-column align-items-center">
          <div>
            <img src={FestDetails.logo} alt="Logo" width={"300px"} />
          </div>
          {/* <h2 className="text-dark text-center">{FestDetails.name}</h2>
          <div className="text-dark-emphasis">
            {dateStrForFestival(FestDetails)}
          </div>
          <div className="text-dark-emphasis text-center">{FestDetails.venue}</div> */}
        </div>
      </div>
      <div className="pb-4 text-center text-dark">
        <p>Powered by</p>
        <img src="/te_logo_text-svg.png" alt="logo" width={100} />
      </div>
    </div>
  );
};

export default SplashScreen;
