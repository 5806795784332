import React from "react"
import { useNavigate } from "react-router-dom"
import { useIsStaff } from "../data/auth"
const BottomMenu = () => {
    const navigate = useNavigate()
    const isStaffUser = useIsStaff()

    const menuItems = [
        { path: "/events", label: "Schedule", iconText: "event" },
        { path: "/profile", label: "Profile", iconText: "account_circle" },
        // { path: "/foodmenu", label: "Food Menu", iconText: "restaurant" },
    ]
    if (isStaffUser)
    menuItems.push(
        { path: "/scan", label: "Scan", iconText: "qr_code_scanner" },
    )
    return (
        <div className="fixed-bottom py-2 shadow-lg bg-white">
            <div className="container d-flex justify-content-around">
                {
                    menuItems.map(b => (
                        <div className="d-flex flex-column align-items-center" onClick={() => navigate(b.path)} >
                            <span className="material-icons">{b.iconText}</span>
                            <span className="fw-light">{b.label}</span>
                        </div>

                    ))
                }
            </div>
        </div>
    )
}

export default BottomMenu
