import moment, { Moment } from "moment";

export type TFestDetails = {
    name: string;
    venue: string;
    year: number;
    startDate: Moment;
    endDate: Moment;
    email: string;
    logo: string;
    website: TWebsite;
    show_individual_leaderboard: boolean;
}

type TWebsite = {
    background?: string;
    contact_numbers?: TContactPerson[]
    venue?: TVenue
}

type TContactPerson = {
    name: string;
    phone_number: string;
    office: string
}

type TVenue = {
    map_iframe: string
}
export type TFestDetailsEnv = {
    name?: string;
    venue?: string;
    year?: number;
    startDate?: string;
    endDate?: string;
    email?: string;
    logo?: string;
    website?: TWebsite;
    show_individual_leaderboard?: boolean;
}

const FestDetailsEnv: TFestDetailsEnv = JSON.parse(process.env.REACT_APP_FEST_DETAILS || "{}");

export const FestDetails: TFestDetails = {
    name: FestDetailsEnv.name || "Kalothsavam",
    venue: FestDetailsEnv.venue || "Venue",
    year: FestDetailsEnv.year || 2023,
    startDate: moment(FestDetailsEnv.startDate || "2023-01-01"),
    endDate: moment(FestDetailsEnv.endDate || "2023-01-01"),
    email: FestDetailsEnv.email || "",
    logo: FestDetailsEnv.logo || "",
    show_individual_leaderboard: FestDetailsEnv.show_individual_leaderboard ?? true,
    website: {
        ...(FestDetailsEnv?.website ?? {}),
        background: FestDetailsEnv?.website?.background ?? "/background.png",
    }
}