import React from "react"
import { QUERY_KEY as CategoryQueryKey, listCategories } from "../data/category"
import { useQuery } from "react-query"

const CategoriesPreLoad = () => {
    useQuery(CategoryQueryKey, listCategories, { staleTime: Infinity, retry: false })
    return (<div className="d-none">&nbsp;</div>)
}

export default CategoriesPreLoad
