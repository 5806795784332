//@ts-nocheck
export type TApiStatusSuccess = {
    message: string
    response: any
}
export type TApiStatusError = {
    message: string,
    status: number,
    response: any
}
export type TApiStatusState = {
    showAlert: boolean
    loading: boolean
    success: TApiStatusSuccess | undefined
    error: TApiStatusError | undefined
}

export const DefaultState = {
    showAlert: false,
    loading: false,
    success: undefined,
    error: undefined
}
export const setApiStatus = (apiCall, data, cb) => {
    apiCall(data)
        .then(response => {
            if ([200, 201, 204].includes(response.status)) {
                cb({
                    showAlert: true,
                    loading: false,
                    success: {
                        message: "Success",
                        response: response.data
                    },
                    error: undefined
                })
            } else {
                cb({
                    showAlert: true,
                    loading: false,
                    success: undefined,
                    error: {
                        message: "Error",
                        status: response.status,
                        response: response.data
                    }
                })
            }
        }).catch(error => {
            cb({
                showAlert: true,
                loading: false,
                success: undefined,
                error: {
                    message: "Some error occurred",
                    status: error.status || 0,
                    response: error.toJSON()
                }
            })
        })
}

