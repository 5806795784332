import React from "react"
import { useNavigate } from "react-router-dom"
//@ts-ignore
import times from "lodash/times"

const TopBar = ({title, padding}: {title: string|JSX.Element, padding: number}) => {
    const navigate = useNavigate()
    return (
        <div className="bg-primary text-white p-3">
            <div className="d-flex m-0 p-0">
                <span className="material-icons md-18" onClick={() => navigate(-1)} >arrow_back</span>
                <h4 className="m-0 p-0 ms-2">{title}</h4>
            </div>
            {
                times(padding, (idx: number) => <div className="p-4" key={idx}>&nbsp;</div>)
            }
            
        </div>
    )
}

export default TopBar
