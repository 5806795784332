//@ts-nocheck
import React, { useEffect } from 'react';
import './App.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './screens/Login';
import Home from './screens/Home';
import 'react-loading-skeleton/dist/skeleton.css'
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'
import EventsIndex from "./screens/Events";
import EventDetail from "./screens/EventDetail";
import FoodMenu from "./screens/FoodMenu";
import Profile from "./screens/Profile";
import Scan from "./screens/Scan";
import UpdateAvailable from "./components/UpdateAvailable"
import RequireAuth, { RequireRoleAuth } from './utils/RequireAuth';
import CategoriesPreLoad from './components/CategoriesPreLoad';
import { FestDetails } from './constants';
import SplashScreen from './screens/SplashScreen';
import ChangePasswordScreen from './screens/ChangePassword';
import Leaderboard from './screens/Leaderboard';

const queryClient = new QueryClient()


function App() {
  useEffect(() => {
    document.title = `${FestDetails.name} - ${FestDetails.year}`;
  }, [])

  const routes = [
    { path: "/login", Component: Login },
    { path: "/", Component: SplashScreen },
    { path: "/events", Component: EventsIndex },
    { path: "/events/:id", Component: EventDetail },
    { path: "/leaderboard/:categoryId", Component: Leaderboard },
    { path: "/foodmenu", Component: FoodMenu },
    { path: "/home", Component: Home },
    { path: "/profile", Component: Profile, requiresLogin: true },
    { path: "/profile/:id", Component: Profile, requiresLogin: true },
    { path: "/change_password", Component: ChangePasswordScreen, requiresLogin: true },
    { path: "/scan", Component: Scan, requiresLogin: true, roles: ['staff', 'admin', 'result'] },
  ]

  return (
    <QueryClientProvider client={queryClient}>
      <UpdateAvailable />
      <CategoriesPreLoad />
      <BrowserRouter>
        <Routes>
          {routes.map(({ path, Component, requiresLogin, roles }) =>
            requiresLogin ?
              roles ? <Route key={path} path={path} element={<RequireRoleAuth roles={roles}><Component /></RequireRoleAuth>} /> :
                <Route key={path} path={path} element={<RequireAuth><Component /></RequireAuth>} />
              : <Route key={path} path={path} element={<Component />} />
          )}
        </Routes>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
