import TopBar from "../components/TopBar";
import React, { useState } from "react";
import Form from "react-bootstrap/esm/Form"
import { useQuery } from "react-query";
import { QUERY_KEY as ScheduleQueryKey, listSchedule } from "../data/schedule";
//@ts-ignore
import times from "lodash/times"

import ScheduleCard from "../components/ScheduleCard";
import classNames from "classnames"
import { QUERY_KEY as EventQueryKey, listEvents } from "../data/event";
import { FestDetails } from "../constants";
import { Moment } from "moment";

const ScheduleIndex = () => {
    const [selectedDate, selectDate] = useState(0)
    const [selectedEvent, setSelectedEvent] = useState(0)

    // get array of dates from FestDetails.startDate to FestDetails.endDate
    const dates: Moment[] = []
    for (let i = 0; i < (FestDetails.endDate.diff(FestDetails.startDate, "days")) + 1; i++) {
        const startDate = FestDetails.startDate.clone()
        dates.push(startDate.add(i, "days"))
    }


    const getParams = () => {
        let params: any = {}
        if (selectedEvent === 0)
            params.date = dates[selectedDate].format("YYYY-MM-DD")
        else
            params.event = selectedEvent
        return params
    }
    const { data: scheduleList, ...scheduleMeta } = useQuery([ScheduleQueryKey, getParams()], listSchedule, {
        staleTime: Infinity,
        refetchOnMount: false, retry: false
        
    })

    const { data: eventList } = useQuery([EventQueryKey], listEvents, { staleTime: Infinity, retry: false })
    const schedules = scheduleList?.data?.results || []

    return (
        <div>
            <TopBar title="Events" padding={3} />
            <div className="container" style={{ marginTop: "-13em" }}>
                <Form.Group>
                    <Form.Select onChange={(e: any) => { e.preventDefault(); setSelectedEvent(e.target.value) }} value={selectedEvent}>
                        <option value={0} disabled>Select Event</option>
                        {(eventList?.data?.results || []).map((e: any) => (
                            <option value={e.id}>{e.name}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
                {
                    selectedEvent !== 0 && (<div className="text-white d-flex align-items-center py-2">
                        Showing results for {eventList?.data?.results.find((e: any) => e.id == selectedEvent)?.name}
                        <a onClick={() => setSelectedEvent(0)} className="text-white underline ms-4">Clear</a>
                    </div>)
                }
                <div className="mt-3 overflow-scroll d-flex justify-content-around">
                    {
                        dates.map((d, index) => (
                            <div
                                style={{ width: "150px" }}
                                className={
                                    classNames(
                                        "text-center", "rounded", "border", "border-white", "me-2", {
                                        "text-white": index !== selectedDate,
                                        "text-primary": index === selectedDate,
                                        "bg-white": index === selectedDate
                                    })
                                }
                                onClick={() => selectDate(index)}>
                                {d.format("D MMM")}
                            </div>
                        ))
                    }
                </div>
                <div className="mt-3">
                    {
                        scheduleMeta.isLoading ? <>
                            {times(5, (idx: number) => (
                                <ScheduleCard key={idx} />
                            ))}
                        </> : schedules.map((schedule: any) => (

                            <div className="bg-white border shadow rounded mb-2">
                                <ScheduleCard schedule={schedule} />
                            </div>

                        ))

                    }
                </div>
            </div>
        </div>
    )
}

export default ScheduleIndex
