import React, { useState } from "react"
import TopBar from "../components/TopBar"
import classnames from "classnames"
import { Table } from "react-bootstrap"

const FoodMenu = () => {
    const [selectedKey, setSelectedKey] = useState("breakfast")

    const foodMenu: any = {
        breakfast: [
            ["Tea", "10"],
            ["Coffee", "10"],
            ["Lemon Tea", "10"],
            ["Mint Tea", "10"],
            ["Dosa", "10"],
            ["Neer Dosa", "10"],
            ["Idly", "10"],
            ["Upma", "10"],
            ["Nookkil Puttu", "10"],
            ["Poori", "10"],
            ["Chappathi", "10"],
            ["Porotta", "10"],
            ["Vellayappam", "10"],
            ["Ilayada", "10"],
            ["Kadala Curry", "15"],
            ["Cherupayar Curry", "15"],
            ["Vegetable Kurma", "20"],
            ["Green Peas Masala", "15"],
            ["Vegetable Curry", "15"],
            ["Masala Curry", "15"],
            ["Gobi Chilli", "30"],
        ],
        snacks: [
            ["Pazhampori", "10"],
            ["Parippuvada", "10"],
            ["Podi", "10"],
            ["Uzhunnu Vada", "10"],
            ["Undakkaayi", "10"],
            ["Sukiyan", "10"],
        ],
        "lunch-veg": [
            ["Meals", 40],
            ["Veg Biriyani", 40]
        ],
        "lunch-non-veg": [
            ["Chicken Biriyani", "70"],
            ["Egg Biriyani", "40"],
            ["Chicken Curry", "30"],
            ["Chicken Fry", "30"],
            ["Chicken Sukka", "30"],
            ["Fish Fry", "30"],
            ["Fish Curry", "30"],
            ["Egg Curry", "20"],
            ["Beef Curry", "50"],
            ["Beef Roast", "50"],
        ],
        cold: [
            ["Ice Cream", "MRP"],
            ["Juices", "20"],
            ["Fresh Juices", "30"],
            ["Tender Coconut", "40"],
            ["Butter Milk", "10"],
            ["Mineral Water", "10/20"],
        ]
    }

    return (
        <div>
            <TopBar title="Food Menu" padding={2} />
            <div className="container" style={{ marginTop: "-8em" }}>
                <div style={{ overflowX: "scroll" }}>
                    <div style={{ width: "800px" }}>
                        {
                            [
                                { key: "breakfast", label: "ബ്രേക്ഫാസ്റ്" },
                                { key: "snacks", label: "എണ്ണ കടികൾ" },
                                { key: "lunch-veg", label: "ലഞ്ച് വെജ്" },
                                { key: "lunch-non-veg", label: "ലഞ്ച് നോൺ വെജ്" },
                                { key: "cold", label: "കോൾഡ്" },
                            ].map((item, idx) => (
                                <div
                                    className={classnames("d-inline-block rounded border border-white text-center me-2", {
                                        "text-white": item.key !== selectedKey,
                                        "text-primary": item.key === selectedKey,
                                        "bg-white": item.key === selectedKey,
                                    })}
                                    style={{ width: "150px" }}
                                    key={idx} 
                                    onClick={() => setSelectedKey(item.key)}
                                    >
                                    {item.label}
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="container rounded bg-white shadow mt-3 py-3">
                    <Table hover striped>
                        <tbody>
                            {
                                foodMenu[selectedKey].map(
                                    (item: React.ReactNode[]) => (
                                        <tr>
                                            <td>{item[0]}</td>
                                            <td>{item[1]}</td>
                                        </tr>
                                    )
                                )
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    )
}
export default FoodMenu
