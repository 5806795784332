import { noAuthAxios } from "./api"

export const QUERY_KEY = "schedule"
export const BASE_URL = "app/schedule/"


export const listSchedule = ({ queryKey }: { queryKey: any }) => {
    const params = queryKey[1]
    const queryParams = Object.keys(params).map(p => `${p}=${params[p]}`).join("&")
    const url = BASE_URL + (queryParams ? `?${queryParams}` : "")
    return noAuthAxios.get(url)
}
