import React, { FormEvent, useState } from "react";
import { Form, Button, Spinner, Alert } from "react-bootstrap";
import { formToJson } from "../data/api";
import {
  DefaultState,
  TApiStatusState,
  setApiStatus,
} from "../utils/apiStatus";
import { changePassword } from "../data/auth";
import TopBar from "../components/TopBar";

const ChangePasswordScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [passwordStatus, setPasswordStatus] =
    useState<TApiStatusState>(DefaultState);

  const handleChangePassword = (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    const data = formToJson(e);
    if (data.new_password !== data.confirm_new_password) {
      setPasswordStatus({
        showAlert: true,
        loading: false,
        error: {
          status: 400,
          response: null,
          message: "Passwords do not match",
        },
        success: undefined,
      });
      return;
    } else if (data.old_password === data.new_password) {
      setPasswordStatus({
        showAlert: true,
        loading: false,
        error: {
          status: 400,
          response: null,
          message: "New password cannot be the same as old password",
        },
        success: undefined,
      });
      return;
    }
    setApiStatus(changePassword, data, setPasswordStatus);
  };

  return (
    <div>
      <TopBar title="Profile" padding={2} />
      <div className="container" style={{ marginTop: "-8em" }}>
        <div className="bg-white shadow rounded p-2">
          <h1>Change Password</h1>
          <Form onSubmit={handleChangePassword} className="mt-2">
            <Form.Group className="mb-2">
              <Form.Label>Old Password</Form.Label>
              <Form.Control type="password" name="old_password" />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>New Password</Form.Label>
              <Form.Control type="password" name="new_password" />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control type="password" name="confirm_new_password" />
            </Form.Group>
            <Form.Group className="mb-2">
              <Button
                type="submit"
                variant="primary"
                disabled={passwordStatus.loading}
              >
                <div className="flex flex-align-center">
                  {passwordStatus.loading && (
                    <Spinner animation="border" size="sm" />
                  )}
                  Change Password
                </div>
              </Button>
            </Form.Group>
          </Form>
          <div className="mt-4">
            {passwordStatus.showAlert && (
              <Alert
                show={passwordStatus.showAlert}
                variant={!!passwordStatus.error ? "danger" : "success"}
              >
                {passwordStatus.error?.response?.status === 400
                  ? "No student associated with this phone number"
                  : passwordStatus.error?.message}
                {passwordStatus.success && "Password changed successfully"}
              </Alert>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordScreen;
