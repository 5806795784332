import React from "react"
import { Card } from "react-bootstrap"
import Skeleton from "react-loading-skeleton"
import { useNavigate } from "react-router-dom"

const ResultCard = ({ result }: { result?: any }) => {
    const navigate = useNavigate()
    return (
        <Card onClick={() => navigate(`/events/${result.event.id}`)} style={{minHeight: "200px"}}>
            <Card.Body>
                <Card.Title>{!result ? <Skeleton /> : result.event.name}</Card.Title>
                <Card.Text>
                    {(result?.items || []).map((row: any) => (
                        <div style={{overflowX: "hidden"}}>
                            <small>{row.position} {row.team.college.name}</small>
                        </div>
                    ))}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default ResultCard
