import { authAxios } from "./api"

export const QUERY_KEY = "students"
export const BASE_URL = "app/students/"

export const listStudents = ({ queryKey }: { queryKey: any }) => {
    const params = queryKey[1]
    const queryParams = Object.keys(params).map(p => `${p}=${params[p]}`).join("&")
    const url = BASE_URL + (queryParams ? `?${queryParams}` : "")
    return authAxios.get(url)
}

export const getStudent = ({queryKey}: {queryKey: any}) => {
    const studentId = queryKey[1]
    return authAxios.get(`${BASE_URL}${studentId}/`)
}

export const getProfile = () => authAxios.get(`${BASE_URL}my_profile/`)
//@ts-ignore
export const verifyStudent = ({id: studentId, ...data}) => authAxios.patch(`${BASE_URL}${studentId}/`, data)
//@ts-ignore
export const verifyStudentForEvent = (data) => authAxios.post(`${BASE_URL}verify/`, data)
