//@ts-nocheck
import React, { useState, useEffect } from "react";
import { QrReader } from "react-qr-reader";
import TopBar from "../components/TopBar";
import { Form, Button, Spinner, Alert } from "react-bootstrap";
import { formToJson } from "../data/api";
import { useQuery } from "react-query";
import { QUERY_KEY, listStudents } from "../data/student";
import isObject from "lodash/isObject";
import { useNavigate } from "react-router-dom";

const Scan = () => {
  const [data, setData] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [studentId, setStudentId] = useState("");

  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    const data = formToJson(e);
    setPhoneNumber(data.phone_number);
  };

  const { data: userSearchResponse, ...userSearchMeta } = useQuery(
    [QUERY_KEY, { phone_number: phoneNumber, id: studentId }],
    listStudents,
    { staleTime: Infinity, enabled: !!phoneNumber || !!studentId, retry: false }
  );

  useEffect(() => {
    if (isObject(data)) {
      setStudentId(data.id);
    }
  }, [data]);

  useEffect(() => {
    if (!userSearchMeta.isLoading && userSearchResponse?.data.count === 1) {
      navigate(`/profile/${userSearchResponse.data.results[0].id}`);
    }
  }, [userSearchResponse, userSearchMeta, navigate]);

  return (
    <div>
      <TopBar title="Scan &amp; Verify" padding={0} />
      <div className="container">
        <QrReader
          constraints={{ facingMode: "environment" }}
          onResult={(result, error) => {
            if (!!result) {
              setData(JSON.parse(result?.text));
            }

            if (!!error) {
              console.info(error);
            }
          }}
          style={{ width: "100%" }}
        />
        <div className="my-2">OR</div>
        <Form onSubmit={handleSearch}>
          <Form.Group>
            <Form.Control
              name="phone_number"
              type="number"
              placeholder="Phone number"
            />
            <Form.Text>Enter the phone number without +91</Form.Text>
          </Form.Group>
          <div className="d-grid">
            <Button type="submit">Search</Button>
          </div>
        </Form>
        {userSearchMeta.isLoading && <Spinner animation="border" />}
        {!userSearchMeta.isLoading && userSearchResponse?.data.count === 0 && (
          <Alert variant="danger">
            No student found with this phone number.
          </Alert>
        )}
        {!userSearchMeta.isLoading && userSearchResponse?.data.count > 1 && (
          <Alert variant="danger">
            More than one student found with same phone number.
          </Alert>
        )}
      </div>
    </div>
  );
};

export default Scan;
