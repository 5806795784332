import React from "react"
import TopBar from "../components/TopBar"
import Skeleton from "react-loading-skeleton"
import { useQuery } from "react-query"
import { QUERY_KEY as ScheduleQueryKey, listSchedule } from "../data/schedule"
import { useParams } from "react-router-dom"
import moment from "moment"
import { Card, Spinner, Table } from "react-bootstrap"
import { QUERY_KEY as ResultQueryKey, detailResult } from "../data/result"

const EventDetail = () => {
    const { id: eventId } = useParams()
    const params = {
        event: eventId
    }
    const { data: schedules, ...scheduleMeta } = useQuery([ScheduleQueryKey, params], listSchedule, { staleTime: Infinity, retry: false })
    const schedule = scheduleMeta.isLoading ? null : schedules?.data.results[0]

    const { data: resultsResponse, ...resultsMeta } = useQuery([ResultQueryKey, eventId], detailResult, { staleTime: Infinity, retry: false })
    const sortedResults = (resultsResponse?.data.items ?? []).sort((a: any, b: any) => a.position - b.position)

    return (
        <div>
            <TopBar title={""} padding={0} />
            <div className="container mt-3">
                <h3>{schedule?.event?.name || <Skeleton />}</h3>
                <div className="d-flex align-items-center">
                    <span className="material-icons me-2">location_on</span>{!schedule ? <Skeleton /> : schedule.venue}
                </div>
                <div className="d-flex align-items-center mt-2">
                    <span className="material-icons me-2">schedule</span>{!schedule ? <Skeleton /> : moment(schedule.from_time).format("hh:mm A, Do MMM")}
                </div>
                <hr />
                <h5>Result</h5>
                {resultsMeta.isLoading && <Spinner animation="border" />}
                {sortedResults.length === 0 &&
                    <Card>
                        <Card.Body>
                            <Card.Text>
                                Waiting for the result to be announced.
                                </Card.Text>
                        </Card.Body>
                    </Card>
                }
                {sortedResults.length !== 0 &&
                    <Card>
                        <Card.Body>
                            <Card.Text>
                                {
                                    sortedResults.map((row: any, idx: number) => (
                                        <div key={idx}>
                                            {row.position === 1 ? "First" : row.position === 2 ? "Second" : row.position === 3 ? "Third": "No Prize"} <br />
                                            <small>Grade: {row.grade}</small><br />
                                            <strong>{row.team.college.name}</strong><br />
                                            <Table striped hover>
                                            {row.team.students.map((s: any) => (
                                                <tr>
                                                <td>
                                                    {s.name}
                                                <small>({s.class_name})</small>
                                                </td>
                                                </tr>
                                            ))}
                                            </Table>
                                            <hr />
                                        </div>
                                    ))
                                }
                            </Card.Text>
                        </Card.Body>
                    </Card>
                }
            </div>
        </div>
    )
}

export default EventDetail
