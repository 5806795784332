//@ts-nocheck
import React, { useState, useEffect } from "react";
import { formToJson } from "../data/api";
import {
  setApiStatus,
  DefaultState,
  TApiStatusState,
} from "../utils/apiStatus";
import { postLogin, login } from "../data/auth";
import { useNavigate, useLocation } from "react-router-dom";
import Form from "react-bootstrap/esm/Form";
import Button from "react-bootstrap/esm/Button";
import Spinner from "react-bootstrap/esm/Spinner";
import { Alert } from "react-bootstrap";
import { FestDetails } from "../constants";

const Login = () => {
  const navigate = useNavigate();
  let location = useLocation();
  let from = location.state?.from?.pathname || "/home";

  const [loginStatus, setLoginStatus] = useState<TApiStatusState>(DefaultState);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoginStatus({ loading: true });

    const data = formToJson(e);
    setApiStatus(postLogin, data, setLoginStatus);
  };

  useEffect(() => {
    if (loginStatus.success) {
      login(loginStatus.success.response.access);
      navigate(from, { replace: true });
    }
  }, [loginStatus, from, navigate]);

  return (
    <div
      style={{ height: "100vh" }}
      className="d-flex flex-column align-items-center justify-content-center bg-light"
    >
      <img
        alt="logo"
        src={FestDetails.logo}
        style={{ width: 128, height: 128 }}
        className="shadow img-fluid rounded-circle"
      />
      <div className="container d-flex flex-column">
        <h4>Login</h4>
        <form onSubmit={handleSubmit} name="loginForm">
          <Form.Group>
            <Form.Label>Phone</Form.Label>
            <Form.Control
              id="txtPhoneNumber"
              name="username"
              label="Phone"
              type="number"
              autoFocus
              placeholder="Ex. 7795937091"
              required
              minLength={10}
              maxLength={10}
            />
            <Form.Text>10 digit phone number without +91</Form.Text>
          </Form.Group>
          <Form.Group className="mt-2">
            <Form.Control
              id="txtOtp"
              name="password"
              label="Password"
              type="password"
            />
          </Form.Group>
          <div className="d-grid">
            <Button
              disabled={loginStatus.loading}
              type="submit"
              className="mt-2"
            >
              Login
              {loginStatus.loading && <Spinner animation="border" size="sm" />}
            </Button>
          </div>
        </form>
      </div>
      <div className="container mt-2">
        {loginStatus.showAlert && (
          <Alert
            show={loginStatus.showAlert}
            variant={!!loginStatus.error ? "danger" : "info"}
          >
            {loginStatus.error?.response?.status === 400
              ? "No student associated with this phone number"
              : loginStatus.error?.message}
          </Alert>
        )}
      </div>
    </div>
  );
};

export default Login;
