import React from "react"
import moment from "moment"
import { Card } from "react-bootstrap"
import Skeleton from "react-loading-skeleton"
import { useNavigate } from "react-router-dom"

const ScheduleCard = ({ schedule }: { schedule?: any }) => {
    const navigate = useNavigate()
    return (
    <Card onClick={() => navigate(`/events/${schedule.event.id}`)}>
        <Card.Body>
            <Card.Title>{!schedule ? <Skeleton /> : schedule.event.name}</Card.Title>
            <Card.Text>
                <div className="d-flex align-items-center">
                    <span className="material-icons me-2">location_on</span>{!schedule ? <Skeleton /> : schedule.venue}
                </div>
                <div className="d-flex align-items-center mt-2">
                    <span className="material-icons me-2">schedule</span>{!schedule ? <Skeleton /> : moment(schedule.from_time).format("hh:mm A, Do MMM")}
                </div>
            </Card.Text>
        </Card.Body>
    </Card>
)}

export default ScheduleCard
