import React, { useMemo, useState } from "react";
import TopBar from "../components/TopBar";
import { useParams } from "react-router-dom";
import { noAuthAxios } from "../data/api";
import { Table } from "react-bootstrap";
import { useQuery } from "react-query";
import {
  QUERY_KEY as CategoryQueryKey,
  listCategories,
} from "../data/category";

const Leaderboard = () => {
  const { categoryId } = useParams();
  const { data: categoryList } = useQuery(CategoryQueryKey, listCategories, {
    staleTime: Infinity, retry: false
  });
  const thisCategoryName =
    (categoryList?.data?.results ?? []).find(
      (row: any) => row.id === parseInt(categoryId ?? "0")
    )?.name ?? "Overall";

  const [leaderboard, setLeaderboard] = useState([]);
  useMemo(async () => {
    const response = await noAuthAxios.get(
      `/app/results/college_leaderboard?category=${categoryId}`
    );
    setLeaderboard(response.data?.results ?? []);
  }, [categoryId]);

  return (
    <div>
      <TopBar title="Leaderboard" padding={2} />
      <div className="container" style={{ marginTop: "-8em" }}>
        <div className="bg-white shadow rounded p-2">
          <h1>Leaderboard</h1>
          <h2>{thisCategoryName}</h2>

          <Table striped>
            {leaderboard.map((row: any) => (
              <tr>
                <td>{row.team__college__name}</td>
                <td>
                  <small>{row.points}</small>
                </td>
              </tr>
            ))}
          </Table>
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
