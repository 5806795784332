
import React from "react"
import { Navigate, useLocation } from "react-router";
import { useIsLoggedIn, getRole } from "../data/auth";
//@ts-ignore
import intersection from "lodash/intersection"

const RequireAuth = ({ children }: {children: any}) => {
  const isLoggedIn = useIsLoggedIn();
  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
}

export const RequireRoleAuth = ({ children, roles }: { children: JSX.Element, roles: string[] }) => {
  const isLoggedIn = useIsLoggedIn();
  const userRoles = getRole();
  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (intersection(roles, userRoles).length === 0) {
    return <Navigate to="/" replace />;
  }

  return children
}


export default RequireAuth
