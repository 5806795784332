//@ts-nocheck
import jwt_decode from "jwt-decode";
import { AUTH_TOKEN_KEY, noAuthAxios, authAxios } from "./api";

export const login = (authToken: string) => {
  localStorage.setItem(AUTH_TOKEN_KEY, authToken);
};

export const logout = () => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
  window.location.href = "/";
};

export const useIsLoggedIn = () => {
  const authToken = localStorage.getItem(AUTH_TOKEN_KEY) || "";
  try {
    jwt_decode(authToken);
    return true;
  } catch (error) {
    return false;
  }
};

export const getRole = () => {
  const authToken = localStorage.getItem(AUTH_TOKEN_KEY) || "";
  try {
    const decoded = jwt_decode(authToken);
    const roles = decoded.roles.map((role) => role.toLowerCase());
    roles.push(decoded.type.toLowerCase());
    return roles;
  } catch (error) {
    return false;
  }
};

export const useIsAdmin = () => {
  const role = getRole();
  return (role || []).includes("admin");
};

export const useIsStaff = () => {
  const role = getRole();
  return (role || []).includes("staff") || (role || []).includes("admin");
};

export const postLogin = ({ username, password }) => {
  return noAuthAxios.post("/auth/login/", {
    username: "+91" + username,
    password,
  });
};


export const changePassword = ({ old_password, new_password }) => {
    return authAxios.post("/app/users/change_password/", {
      old_password,
      new_password,
    });
  };
  